import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/LegalDocs.css";
import logo from "../Assets/logo_black.png"; // Corrige la ruta de importación


function LegalDocs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">
      <img 
              src={logo} 
              alt="Family Insurance Logo" 
              className="ft-logo" 
              style={{ width: '233px', height: 'auto', verticalAlign: 'middle' }} 
            /> 
      </h1>

      <div className="legal-text-content">
  

        <p className="legal-title">Privacy Policy</p>
        <p className="legal-description" style={{ textAlign: 'justify' }}>
        Last update: March 30th, 2025
        <br /><br />
        <strong>Information Collection: </strong>

        At Legal Services Enterprise, we value your privacy and are committed to protecting your personal information. We want to assure you that our website does not
        collect any personal data from our visitors. Our primary purpose is to provide you with comprehensive information about our services, ensuring that you have all
          the necessary details to make informed decisions.
          <br /><br />
          We strictly limit our website to providing links to our social networks, contact phone number, email address, and the physical address of our
        office. This information is provided solely for your convenience and to facilitate communication with us. We do not use any tracking technologies, such as cookies
          or web beacons, to gather information about your browsing habits or preferences.
          <br /><br />

          <strong>Use of Information: </strong>

        The information provided on our website is intended to inform and assist you regarding our services. We do not share, sell, or distribute any data about your
        visits to our site, as we do not collect any personal information to begin with. Our goal is to ensure that you feel secure while navigating our website, knowing
          that your privacy is fully respected.
          <br /><br />

        <strong>Contact Information: </strong>

        If you have any questions or concerns about our privacy practices, please do not hesitate to contact us via the provided phone number, email address. We are 
        here to ensure your experience with our services is transparent, secure, and trustworthy.
        <br /><br />
        By using our website, you acknowledge that you have read and understood our privacy policy. We reserve the right to update this policy as needed to reflect 
        changes in our practices or for other operational, legal, or regulatory reasons.
        <br /><br />
        <strong>Messaging Privacy Policy: </strong>
        <br /><br />
        <strong>*Consent: </strong>
        Customers may opt-in to receive text message communications by completing the contact form located at <a href="/contact" className="contact-link">https://legalservicesenterprise.com/contact</a> and checking the checkbox indicating their consent to receive messages. By choosing this option, you agree to receive SMS communications from Legal Services Enterprise LLC at the phone number you provided. These messages will be used for marketing and customer care purposes. Your consent is not a condition of purchase. Message and data rates may apply. Message frequency may vary.
        <br /><br />
        <strong>Mobile information will not be shared with third parties/affiliates for marketing/promotional purposes. All the above categories exclude text messaging originator opt-in 
        data and consent; this information will not be shared with third parties.</strong>
        <br /><br />
        <strong>*HELP and Opt-out instructions:  </strong>
        If you would like to be removed from receiving future messages, you may opt out of receiving text messages at any time by replying the word “STOP”. By opting out of receiving marketing and customer care messages from our organization, you will no longer receive them. If you have any questions or need further assistance, reply “HELP” or call our office at (832) 303-0276.
        </p>

        
        
      </div>

      <div className="legal-footer">
        <p>© 2025 Legal Services Enterprise LLC. All rights reserved.</p>
      </div>
    </div>
  );
}

export default LegalDocs;
